import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <noscript
          aria-hidden="true"
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PJSTS9Z" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager">Google Tag Manager</iframe>'
          }}
        />
        <header className="main-header wrapper">
          <h1 className="logo aligncenter">
            <a href="/">Rovi - Supositorios de glicerina</a>
          </h1>
          <a href="#" className="btn-menu" />
        </header>
        <div className="main-menu">
          <div className="wrapper">
            <div className="row">
              <nav className="main-nav">
                <ul
                  className="inline-list alignleft nice-menu nice-menu-right nice-menu-main-menu"
                  id="nice-menu-1"
                >
                  <li className="menu-218 menu-path-front first odd ">
                    <a href="/" title>
                      Inicio
                    </a>
                  </li>
                  <li className="menu-308 menu-path-node-1  even ">
                    <a href="/por-que-rovi/">Nuestra marca</a>
                  </li>
                  <li className="menu-313 menuparent  menu-path-node-2  odd ">
                    <a href="/supositorios-de-glicerina/">
                      Supositorios
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-314 menu-path-node-3 first odd ">
                        <a href="/supositorios-de-glicerina/que-son/">
                          ¿Qué son los supositorios de glicerina?
                        </a>
                      </li>
                      <li className="menu-315 menu-path-node-4  even ">
                        <a href="/supositorios-de-glicerina/origen-vegetal/">
                          La Glicerina: origen vegetal
                        </a>
                      </li>
                      <li className="menu-316 menu-path-node-5  odd last">
                        <a href="/supositorios-de-glicerina/ventajas/">
                          Ventajas
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-317 menuparent  menu-path-node-6  even ">
                    <a href="/productos/">
                      Productos
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-319 menu-path-node-7 first odd ">
                        <a href="/productos/rovi-adultos/">Adultos</a>
                      </li>
                      <li className="menu-318 menu-path-node-8  even ">
                        <a href="/productos/rovi-ninos/">Niños</a>
                      </li>
                      {/* <li className="menu-320 menu-path-node-9  odd last">
                        <a href="/productos/">Lactantes</a>
                      </li> */}
                    </ul>
                  </li>
                  <li className="menu-349 menuparent  menu-path-node-10 active  odd ">
                    <a href="/estrenimiento/">
                      Estreñimiento
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-350 menu-path-node-11 first odd ">
                        <a href="/estrenimiento/que-es/">¿Qué es?</a>
                      </li>
                      <li className="menu-351 menu-path-node-12  even ">
                        <a href="/estrenimiento/tipos/">
                          Tipos de estreñimiento
                        </a>
                      </li>
                      <li className="menu-352 menu-path-node-13  odd ">
                        <a href="/estrenimiento/causas/">
                          Causas del estreñimiento
                        </a>
                      </li>
                      <li className="menu-353 active m:node/14 q: node/14  even last">
                        <a href="/estrenimiento/mitos/" className="active">
                          Mitos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-354 menuparent  menu-path-node-15  even last">
                    <a href="/ninos/">
                      Los niños y el estreñimiento
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-360 menuparent  menu-path-node-21 first odd ">
                        <a href="/ninos/estrenimiento-lactantes/">
                          Lactantes
                          <span className="icon icon-v-down" />
                        </a>
                        <ul>
                          <li className="menu-361 menu-path-node-22 first odd ">
                            <a href="/ninos/estrenimiento-lactantes/causas/">
                              Causas
                            </a>
                          </li>
                          <li className="menu-362 menu-path-node-23  even ">
                            <a href="/ninos/estrenimiento-lactantes/test-escala-bristol/">
                              Test
                            </a>
                          </li>
                          <li className="menu-363 menu-path-node-24  odd ">
                            <a href="/ninos/estrenimiento-lactantes/recomendaciones/">
                              Recomendaciones
                            </a>
                          </li>
                          <li className="menu-364 menu-path-node-25  even last">
                            <a href="/ninos/estrenimiento-lactantes/alimentacion/">
                              Alimentación
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-355 menuparent  menu-path-node-16  even last">
                        <a href="/ninos/estrenimiento-infantil/">
                          Niños
                          <span className="icon icon-v-down" />
                        </a>
                        <ul>
                          <li className="menu-356 menu-path-node-17 first odd ">
                            <a href="/ninos/estrenimiento-infantil/causas/">
                              Causas
                            </a>
                          </li>
                          <li className="menu-357 menu-path-node-18  even ">
                            <a href="/ninos/estrenimiento-infantil/test-escala-bristol/">
                              Test
                            </a>
                          </li>
                          <li className="menu-358 menu-path-node-19  odd ">
                            <a href="/ninos/estrenimiento-infantil/recomendaciones/">
                              Recomendaciones
                            </a>
                          </li>
                          <li className="menu-359 menu-path-node-20  even last">
                            <a href="/ninos/estrenimiento-infantil/alimentacion/">
                              Alimentación
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="breadcrumbs">
          <div className="wrapper">
            <div className="row">
              <ul className="inline-list l-grid-9">
                <li>
                  <a href="/">Inicio</a>
                </li>
                <li>
                  <a href="/estrenimiento/">Estreñimiento</a>
                </li>
                <li>Mitos</li>
              </ul>{" "}
            </div>
          </div>
        </div>
        <div className="main-content wrapper">
          <div className="l-grid-9">
            <h1>Mitos del estreñimiento</h1>
            <p>
              Diversos estudios han demostrado, de forma científica, que muchos
              de los mitos sobre el estreñimiento que existen a día de hoy
              carecen de base <strong>total</strong> o <strong>parcial</strong>.
            </p>
          </div>
          <div className="row">
            <div className="l-grid-9">
              <div id="accordion">
                <h2>¿El consumo de mucha agua previene el estreñimiento?</h2>
                <div>
                  <p>
                    <span
                      style={{
                        color: "#323232",
                        "font-family": "'Open sans', Arial, sans-serif",
                        "font-size": "14px",
                        "line-height": "24px"
                      }}
                    >
                      El consumo de líquidos por sí solo no es válido como
                      preventivo ni como tratamiento en el estreñimiento. No
                      obstante, la deshidratación puede ser un factor
                      determinante a la hora de aparecer el estreñimiento.
                    </span>
                  </p>
                </div>
                <h2>¿El queso causa estreñimiento?</h2>
                <div>
                  <p>
                    <span
                      style={{
                        color: "#323232",
                        "font-family": "'Open sans', Arial, sans-serif",
                        "font-size": "14px",
                        "line-height": "24px"
                      }}
                    >
                      Aunque el consumo de productos lácteos se ha relacionado
                      con la aparición de estreñimiento, debido principalmente a
                      la presencia de grasas y escaso contenido en fibra, es
                      cierto que no se ha determinado de forma fehaciente esta
                      relación.
                    </span>
                  </p>
                </div>
                <h2>¿El envejecimiento causa estreñimiento?</h2>
                <div>
                  <p>
                    <span
                      style={{
                        color: "#323232",
                        "font-family": "'Open sans', Arial, sans-serif",
                        "font-size": "14px",
                        "line-height": "24px"
                      }}
                    >
                      La edad de forma aislada no es factor preventivo ni
                      desencadenante del estreñimiento. Sin embargo, es
                      innegable que la mayoría de la población anciana presente
                      mayores problemas para lograr un adecuado ritmo intestinal
                      y régimen de deposiciones. Se considera que la gente mayor
                      tiene un mayor riego de estreñimiento debido a un consumo
                      más bajo de fibra, uso de medicinas, inmovilidad y
                      enfermedades.
                    </span>
                  </p>
                </div>
                <h2>¿Los cambios hormonales pueden producir estreñimiento?</h2>
                <div>
                  <p>
                    No existen estudios o datos que puedan demostrar de forma
                    firme que los cambios hormonales estén relacionados con el
                    estreñimiento. De forma general, a lo largo de la vida, la
                    incidencia de estreñimiento varía entre niños y niñas,
                    siendo más común en niños durante las etapas infantiles,
                    pasando a ser más común en mujeres durante la adolescencia y
                    en la edad adulta. Sin embargo, durante el embarazo el aumento de
                    progesterona puede contribuir al estreñimiento. Por otra
                    parte, no solo las hormonas sexuales pueden estar implicadas
                    en el estreñimiento, sino que distintas hormonas, presentes
                    en los procesos intestinales, pueden alterar o favorecer el
                    tránsito intestinal.
                  </p>
                </div>
                <h2>
                  ¿Se debe el estreñimiento a una dieta pobre en fibras? ¿El
                  aporte de fibra dietética es el mejor tratamiento?
                </h2>
                <div>
                  <p>
                    No hay duda de que la fibra dietética aumenta el volumen de
                    las heces y la frecuencia de la defecación reduciendo la
                    consistencia de las heces en las personas sanas. También se
                    observa una reducción del tiempo del tránsito intestinal
                    medio y un aumento del peso medio de las heces. 
                    Debemos concluir que aunque una dieta pobre
                    en fibras no debe considerarse como la causa principal del
                    estreñimiento, puede ser un factor que contribuya en
                    un subgrupo en el cual una dieta rica en fibra puede ser de
                    gran ayuda, pero muchos pacientes que presentan un
                    estreñimiento mucho más grave pueden experimentar un
                    empeoramiento de sus síntomas al aumentar la ingesta de
                    fibras dietéticas. Consulte en caso de duda a un
                    especialista para recomendar el aporte diario adecuado a
                    cada caso en particular.
                  </p>
                </div>
                <h2>
                  ¿Contribuye el estilo de vida sedentario al estreñimiento? ¿Es
                  beneficiosa la actividad física?
                </h2>
                <div>
                  <p>
                    La actividad física afecta a la función motora del colon.
                    Tanto es así que durante el sueño los intestinos están
                    relativamente inactivos y se produce un aumento de la
                    actividad intestinal cuando la persona se despierta. Una
                    actividad física moderada puede ayudar a personas con
                    estreñimiento leve, Los programas encaminados a aumentar la 
                    actividad física pueden ser de gran ayuda en ancianos, 
                    sobre todo como parte de un programa de rehabilitación más amplio.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="crosslinks">
          <div className="wrapper">
            <div className="row">
              <div
                id="node-37"
                className="crosslink crosslink-h one-half center"
                about="/node/37"
                typeof="sioc:Item foaf:Document"
              >
                <span
                  property="dc:title"
                  content="Supositorios de glicerina Rovi para adultos, niños y lactantes"
                  className="rdf-meta element-hidden"
                />
                <span
                  property="sioc:num_replies"
                  content="0"
                  datatype="xsd:integer"
                  className="rdf-meta element-hidden"
                />{" "}
                <div className="alignleft ">
                  <img
                    src="/sites/default/files/xlink-rovi-ninos-adultos.jpg"
                    alt="Gama de productos Rovi"
                  />
                </div>
                <div className="crosslink-content">
                  <p>
                    Supositorios de glicerina
                    <br /> Rovi para adultos
                    <br /> y niños
                  </p>{" "}
                  <a href="/productos/" className="btn">
                    Saber más
                  </a>
                </div>
              </div>
              <div
                id="node-41"
                className="crosslink crosslink-h one-half center"
                about="/node/41"
                typeof="sioc:Item foaf:Document"
              >
                <span
                  property="dc:title"
                  content="¿Qué es el estreñimiento?"
                  className="rdf-meta element-hidden"
                />
                <span
                  property="sioc:num_replies"
                  content="0"
                  datatype="xsd:integer"
                  className="rdf-meta element-hidden"
                />{" "}
                <div className="alignleft ">
                  <img
                    src="/sites/default/files/xlink-que-es-estrenimiento.jpg"
                    alt="Que es el estreñimiento"
                  />
                </div>
                <div className="crosslink-content">
                  <p>
                    ¿Qué es el
                    <br /> estreñimiento?
                  </p>{" "}
                  <a href="/estrenimiento/" className="btn">
                    Descúbrelo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="legales">
          <nav className="wrapper">
            <ul className="inline-list aligncenter">
              <li>
                <a href="/aviso-legal/">Aviso legal</a>
              </li>
              <li>
                <a href="/politica-de-privacidad/">Política de privacidad</a>
              </li>
              <li>
                <a href="/politica-cookies/">Política de cookies</a>
              </li>
              <li>
                <a href="/mapa-del-sitio/">Mapa del sitio</a>
              </li>
              <li>
                <a href="/contacto/">Contacto</a>
              </li>
              <li>
                <a href="/preguntas-frecuentes-estrenimiento/">FAQs</a>
              </li>
              <li>
                <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">
                  Configuración de cookies
                </a>
              </li>
            </ul>{" "}
          </nav>
        </div>
        <footer className="wrapper">
          <nav className="footer-seo row">
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/por-que-rovi/">Nuestra marca</a>
                </li>
                <li className="vs-3">
                  <a href="/supositorios-de-glicerina/">Supositorios</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/supositorios-de-glicerina/que-son/">
                        ¿Qué son los supositorios de glicerina?
                      </a>
                    </li>
                    <li>
                      <a href="/supositorios-de-glicerina/origen-vegetal/">
                        La glicerina: origen vegetal
                      </a>
                    </li>
                    <li>
                      <a href="/supositorios-de-glicerina/ventajas/">
                        Ventajas
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/productos/">Productos</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/productos/rovi-adultos/">Adultos</a>
                    </li>
                    <li>
                      <a href="/productos/rovi-ninos/">Niños</a>
                    </li>
                    {/* <li>
                      <a href="/productos/">Lactantes</a>
                    </li> */}
                  </ul>
                </li>
                <li className="vs-3">
                  <a href="/estrenimiento/">estreñimiento</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/estrenimiento/que-es/">¿Qué es?</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/tipos/">Tipos</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/causas/">Causas</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/mitos/">Mitos</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/ninos/">Los niños y el estreñimiento</a>
                  <ul className="nivel-1">
                    <li>
                      <a href="/ninos/estrenimiento-infantil/">Niños</a>
                      <ul className="nivel-2">
                        <li>
                          <a href="/ninos/estrenimiento-infantil/causas/">
                            Causas
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/test-escala-bristol/">
                            Test
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/recomendaciones/">
                            Recomendaciones
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/alimentacion/">
                            Alimentación
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="/ninos/estrenimiento-lactantes/">Lactantes</a>
                      <ul className="nivel-2">
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/causas/">
                            Causas
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/test-escala-bristol/">
                            Test
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/recomendaciones/">
                            Recomendaciones
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/alimentacion/">
                            Alimentación
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="copy l-grid-3 m-full-width">
              <img
                src="/sites/all/themes/custom/rovi/images/html/logo-footer.png"
                alt="logo Rovi"
              />
              <p className="vs-2">
                <strong>©JNTL Consumer Health (Spain), S.L.</strong>
              </p>
              <p className="vs-2">
                Este sitio web está publicado
                <br className="m-hidden" />
                por JNTL Consumer Health (Spain), S.L.
                <br className="m-hidden" />
                único responsable de su contenido.
                <br />
                Está dirigida a visitantes de España.
              </p>
              <p className="semi-bold vs-2">Última actualización: marzo 2024</p>
            </div>
          </nav>
        </footer>
        <script src="/sites/all/themes/custom/rovi/js/vendor/jquery.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/vendor/jquery-ui.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/vendor/picturefill.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/main.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nvar scripts_loaded = false;\nfunction setCookie(name , state, days) {\ndate = new Date();\ndate.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));\nexpiresDate = date.toGMTString();\nwindow.document.cookie = name +'='+ state +'; expires=' + expiresDate + \"; path=/\";\n}\nfunction loadScripts() {\nscripts_loaded = true;\n}\nfunction jnjAcceptCookiesPolicy( e ) {\nsetCookie('jnj-cookies-policy', 'true', 1000);\nloadScripts();\nif ( $('#cookie-policy').length > 0 ) {\n$('#cookie-policy').remove();\n}\n}\n$(document).ready(function() {\n$(document).one( 'click', '#btn-accept-cookies-box', jnjAcceptCookiesPolicy );\n$(document).one('click', '#btn-close-cookies-box', function() {\nif ( $('#cookie-policy').length > 0 ) {\n$('#cookie-policy').remove();\n}\n});\n});\n"
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/themes/custom/rovi/js/myths.js?q933yp"
        />
      </div>
    );
  }
}

export default Page;
